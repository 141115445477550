(function (_this, $) {
  var hamburgerMenuRevealed = false;
  var topMenuDetailsRevealed = false;

  _this.initialise = function () {
    $(function () {
      bindHamburgerMenu();
      bindClickDarkener();
      bindMainNavClick();
      showMenuItems();
      bindBackButton();
      bindSubMenuItems();
    });

    return _this;
  }

  var bindHamburgerMenu = function () {
    $('.hamburgerIcon').on('click', function (e) {
      e.preventDefault();

      if (hamburgerMenuRevealed) {
        _this.closeHamburgerMenu();
      }
      else {
        _this.openHamburgerMenu();
      }
    });
  }


  var showMenuItems = function () {

    $('.topMenuNavigationModule .list-group-item').on('click', function () {
      $('.topMenuNavigationModule .list-group-item').removeClass('active');
      $(this).toggleClass('active');
      //$(this).find('.fa')
      //    .toggleClass('fa-angle-down fa-angle-up');

    });
  }

  var bindMainNavClick = function () {

    $('.navItems a', '.mobile .mainNav').on('click', function () {

    });
  }

  var bindSubMenuItems = function () {

    $('.navItems a', '.mobile .mainNav').on('click', function (e) {

      var $this = $(this);
      var menu = $this.data('menu');

      if(!menu){
        return true;
      }

      $('.homePage').addClass('fixedPosition');
      $(".navItems a", '.mobile .mainNav').not($(this)).addClass("hidden");
      $(this).addClass("active");
      $('.mainMenu').show();
      $('.navItems > span', '.mobile .mainNav').removeClass('hidden');

      e.preventDefault();
      var hidden = false;
      if (topMenuDetailsRevealed) {

        $.each($('.navItems a', '.mobile .mainNav').not($(this)), function (i, n) {
          if ($(n).hasClass('hidden')) {
            hidden = true;
            return false;
          }
        })
        if (!hidden)
          _this.closeTopMenuDetails();

      }
      else {
        _this.openTopMenuDetails(menu);
      }
    });
  }


  var bindBackButton = function () {

    $('.navItems > span').on('click', function () {
      $('.homePage').removeClass('fixedPosition');
      $('.navItems > span', '.mobile .mainNav').addClass('hidden');
      $('.navItems', '.mobile .mainNav').find('.active').removeClass('active');
      $(".navItems a", '.mobile .mainNav').removeClass("hidden");
      $('.searchRow').removeClass('hidden');
      $('.searchRow .searchWrapper ').removeClass('fixed');
      $('html, body').scrollTop(2);
      //   $(".navItems a", '.mobile .mainNav').css("display", "inline-block");
      $('.mainMenu').hide();

      _this.closeTopMenuDetails();


    });
  }

  var bindClickDarkener = function () {
    $('.darkener').on('click', function () {
      _this.closeHamburgerMenu();
    });
  }

  _this.openHamburgerMenu = function () {

    //$('.searchWrapper').removeClass('fixed');
    //$('.mainNavigation, .primaryNavigation').removeClass('fixed');
    //$('html, body').scrollTop(2);
    $('body').addClass("hideOverflow");
    $('.mobileNavigationModule').addClass('revealed');
    $('.darkener').addClass('revealed');
    $('header.mobile a.hamburgerIcon').addClass('open');


    hamburgerMenuRevealed = true;
  }

  _this.closeHamburgerMenu = function () {

    $('.mobileNavigationModule, .darkener').removeClass('revealed');
    $('header.mobile a.hamburgerIcon').removeClass('open');
    $('body').removeClass("hideOverflow");
    hamburgerMenuRevealed = false;
  }

  _this.openTopMenuDetails = function (menu) {

    var $menu = $(menu);
    $('.topMenuNavigationModule .mainMenuDropdown').hide();
    $menu.show();

    $('.topMenuNavigationModule').addClass('revealed');
    //  $('.darkener').addClass('revealed');
    $('.searchRow').addClass('hidden');
    topMenuDetailsRevealed = true;
  }

  _this.closeTopMenuDetails = function () {

    $('.topMenuNavigationModule').removeClass('revealed');
    $('header.mobile a.hamburgerIcon').removeClass('open');

    topMenuDetailsRevealed = false;
  }

  // Initialise & assign to global scope
  window.MobileNavigationModule = _this.initialise();
})(window.MobileNavigationModule || {}, jQuery);